import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import IconCheckBlue from 'images/icons/icon-check-blue.svg';
import { SELECTBOX_OPTION_LABEL_CLASSNAME } from 'components/commons/SelectBox/consts';
import { SelectBoxContext } from 'components/commons/SelectBox/SelectBoxContext';
import { SelectBoxDropdownItem } from 'components/commons/SelectBox/SelectBoxDropdown';
import classNames from 'classnames';

type SelectBoxOptionBaseStyle = BoxStyle & BoxShapeStyle & FontStyle;

type SelectBoxOptionStyleOnEvent = {
  onActive?: SelectBoxOptionBaseStyle;
};

export type SelectBoxOptionLabelStyle = FontStyle & BoxStyle;

export type SelectBoxOptionStyle = SelectBoxOptionBaseStyle & SelectBoxOptionStyleOnEvent;

type SelectBoxOptionContainerProps = {
  labelStyle?: SelectBoxOptionLabelStyle;
  disabled?: boolean;
} & Styleable<SelectBoxOptionStyle>;

type SelectBoxDefaultOptionProps = {
  hideIcon?: boolean;
  label?: React.ReactNode;
  searchValue?: string;
} & SelectBoxOptionContainerProps;

type SelectBoxOptionProps = {
  option: ValueWithLabel<any>;
  hideIcon?: boolean;
  searchValue?: string;
  iconStyle?: React.CSSProperties;
} & SelectBoxOptionContainerProps;

export const OptionLabel = styled.div<{ styles?: SelectBoxOptionLabelStyle; disabled?: boolean }>`
  display: ${props => props.styles?.display || 'block'};
  flex: 1 1 0%;
  gap: ${props => props.styles?.gap || '0px'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${props => props.styles?.whiteSpace || 'nowrap'};
  ${({ styles }) => styles?.maxWidth && `max-width: ${styles.maxWidth};`}

  ${props =>
    props.styles?.padding &&
    css`
      padding: ${props.styles.padding};
    `}

  ${({ styles }) => styles?.fontWeight && `font-weight: ${styles.fontWeight};`}
  font-size: ${props => props.styles?.fontSize || '13px'};
  line-height: ${props => props.styles?.lineHeight || '1.5'};
  text-align: ${props => props.styles?.textAlign || 'left'};
  color: ${props => {
    if (props.styles?.color) {
      return props.styles.color;
    }
    if (props.disabled) {
      return '#D9D8D8';
    }
    return '#000000';
  }};
`;

export const SelectBoxOptionContainer = styled(SelectBoxDropdownItem)<SelectBoxOptionContainerProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => props.styles?.justifyContent || 'flex-start'};
  padding: ${props => props.styles?.padding || '0 12px'};
  height: ${props => props.styles?.height || '40px'};
  ${({ styles }) => styles?.columnGap && `column-gap: ${styles.columnGap};`}
  ${({ styles }) => styles?.borderTop && `border-top: ${styles.borderTop};`}

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : 'rgba(4, 125, 224, 0.05)')};
  }

  &.active {
    padding: ${props => props.styles?.onActive?.padding || '0 50px 0 12px'};
    ${props => props.styles?.onActive?.backgroundColor && `background-color: ${props.styles.onActive.backgroundColor};`}
  }

  & > ${Icon} {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate3d(0, -50%, 0);
  }
`;

export const SelectBoxDefaultOption: React.FC<React.PropsWithChildren<SelectBoxDefaultOptionProps>> = ({
  className,
  styles,
  label,
  labelStyle,
  hideIcon,
  searchValue,
}) => {
  const [show, toggleShow] = useState(true);
  const { values, defaultLabel, searchValue: defaultSearchValue, onDefaultOptionSelect } = useContext(SelectBoxContext);
  const selected = values.length === 0;

  const handleOnClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (onDefaultOptionSelect) {
      onDefaultOptionSelect();
    }
  };

  useEffect(() => {
    const optionLabel = (defaultLabel ?? '').toLowerCase();
    const searchKeyword = (searchValue ?? defaultSearchValue).trim().toLowerCase();

    toggleShow(optionLabel.includes(searchKeyword));
  }, [defaultLabel, searchValue, defaultSearchValue]);

  if (!show) {
    return null;
  }

  return (
    <SelectBoxOptionContainer
      className={classNames(className, { active: selected })}
      onClick={handleOnClick}
      styles={styles}
    >
      <OptionLabel styles={labelStyle}>{label ?? defaultLabel}</OptionLabel>
      {!hideIcon && selected && <Icon url={IconCheckBlue} width={16} height={11} />}
    </SelectBoxOptionContainer>
  );
};

export const SelectBoxOption: React.FC<React.PropsWithChildren<SelectBoxOptionProps>> = ({
  className,
  option,
  styles,
  labelStyle,
  iconStyle,
  hideIcon,
  disabled,
  children,
  searchValue,
}) => {
  const {
    values,
    onOptionSelect,
    onOptionUnselect,
    searchValue: defaultSearchValue,
    multi,
  } = useContext(SelectBoxContext);
  const [selected, toggleSelected] = useState(false);
  const [show, toggleShow] = useState(true);

  const findIndex = useCallback(() => {
    return values.findIndex(valueItem => valueItem.value === option.value);
  }, [option, values]);

  const select = () => {
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };

  const unselect = () => {
    const index = findIndex();
    if (onOptionUnselect && index > -1) {
      onOptionUnselect(index);
    }
  };

  const handleClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (!disabled) {
      if (selected && multi) {
        unselect();
      } else {
        select();
      }
    }
  };

  useEffect(() => {
    toggleSelected(findIndex() > -1);
  }, [findIndex]);

  useEffect(() => {
    const searchKeyword = (searchValue ?? defaultSearchValue).trim().toLowerCase();

    if (searchKeyword) {
      toggleShow(option.label.toLowerCase().includes(searchKeyword));
    } else {
      toggleShow(true);
    }
  }, [searchValue, option, defaultSearchValue]);

  return (
    <>
      {show && (
        <SelectBoxOptionContainer
          className={classNames(className, { active: selected })}
          onClick={handleClick}
          styles={styles}
          disabled={disabled}
        >
          <OptionLabel className={SELECTBOX_OPTION_LABEL_CLASSNAME} styles={labelStyle} disabled={disabled}>
            {children || option.label}
          </OptionLabel>
          {!hideIcon && selected && <Icon url={IconCheckBlue} width={16} height={11} style={{ ...iconStyle }} />}
        </SelectBoxOptionContainer>
      )}
    </>
  );
};
