import React, { ButtonHTMLAttributes, useCallback, useContext, useEffect, useRef } from 'react';

import { Icon } from 'components/atoms/Icon';
import IconDeleteX from 'images/icons/icon-delete-x.png';
import IconDown from 'images/icons/icon-down.png';
import IconDownSky from 'images/icons/icon-down-sky.svg';
import { SelectBoxContext } from 'components/commons/SelectBox/SelectBoxContext';
import { TooltipOnHover } from '../Tooltip/GlobalTooltip/TooltipOnHover';
import colors from 'theme/colors';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export type SelectBoxToggleIconProps = {
  width?: string;
  height?: string;
  borderLeft?: string;
  minHeight?: string;
  maxHeight?: string;
  open?: { url?: string };
  close?: { url?: string };
};

export type SelectBoxToggleLabelType = 'plainText' | 'item' | 'itemWithText' | 'defaultText';

export type SelectBoxToggleLabelTypeProps = {
  labelType?: SelectBoxToggleLabelType;
};

export type SelectBoxToggleLabelProps = BoxStyle & FontStyle;

export type SelectBoxToggleDefaultLabelStyle = FontStyle;

type SelectBoxToggleLabelItemStyleProps = FontStyle &
  BoxShapeStyle &
  BoxStyle & { iconStyles?: SelectBoxToggleLabelItemIconStyleProps };

type SelectBoxToggleLabelItemIconStyleProps = {
  display?: DisplayType;
};

type SelectBoxToggleSharedProps = {
  styles?: BoxStyle;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  boxShadowOnOpen?: string;
  backgroundColor?: string;
  backgroundColorOnOpen?: string;
  backgroundColorOnDisabled?: string;
  color?: string;
  colorOnDisabled?: string;
  fontSize?: string;
};

type SelectBoxToggleProps = {
  labelStyle?: SelectBoxToggleLabelProps;
  defaultLabelStyle?: SelectBoxToggleDefaultLabelStyle;
  iconStyle?: SelectBoxToggleIconProps;
  isDisabled?: boolean;
  hideButton?: boolean;
  itemRenderer?(option: ValueWithLabel, index?: number): JSX.Element;
  tooltipEnabled?: boolean;
  onElementLoaded?(element: HTMLButtonElement): void;
} & Styleable &
  SelectBoxToggleSharedProps &
  SelectBoxToggleLabelTypeProps;

type SelectBoxToggleContainerProps = {
  open: boolean;
  disabled: boolean;
  hasError: boolean;
} & SelectBoxToggleSharedProps;

const SelectBoxToggleLabelItemContainer = styled.span<{ styles?: SelectBoxToggleLabelItemStyleProps }>`
  display: ${props => props.styles?.display || 'inline-flex'};
  align-items: center;
  justify-content: center;
  padding: ${props => props.styles?.padding || '0 11px 0 13px'};
  height: 24px;
  border-radius: 100px;
  vertical-align: middle;

  background-color: ${props => props.styles?.backgroundColor || '#eaf0f5'};
  color: ${props => props.styles?.color || '#000000'};
  ${props => props.styles?.border && `border: ${props.styles.border};`}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}

  font-size: ${props => props.styles?.fontSize || '12px'};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.2px;
  line-height: normal;

  & > ${Icon} {
    display: ${props => props.styles?.iconStyles?.display || 'inline-flex'};
    margin-left: 4px;
  }
`;

const SelectBoxToggleLabelItem: React.FC<
  React.PropsWithChildren<Styleable<SelectBoxToggleLabelItemStyleProps> & { onRemoveClick(): void }>
> = ({ className, onRemoveClick, styles, children }) => {
  const handleRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemoveClick();
  };

  return (
    <SelectBoxToggleLabelItemContainer className={className} styles={styles}>
      {children}
      <Icon url={IconDeleteX} width={10} height={10} onClick={handleRemoveClick} />
    </SelectBoxToggleLabelItemContainer>
  );
};

export const ToggleLabel = styled.span<SelectBoxToggleLabelProps & SelectBoxToggleLabelTypeProps>`
  flex: 1;
  padding: ${props => props.padding || '0 15px'};
  min-width: 0;
  ${props => {
    if (props.labelType === 'item') {
      return `overflow: ${props.overflow || 'scroll'};`;
    }
    return `
      overflow: ${props.overflow || 'hidden'};
      text-overflow: ellipsis;
    `;
  }}

  white-space: ${props => props.whiteSpace || 'nowrap'};

  ${props => props.color && `color: ${props.color};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
  text-align: left;

  ${SelectBoxToggleLabelItemContainer} + ${SelectBoxToggleLabelItemContainer} {
    margin-left: 6px;
  }
`;

const DefaultLabel = styled.div<{ styles?: FontStyle }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.styles?.color ?? props.theme.mantineColors.grey[8]};
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  font-weight: ${props => props.styles?.fontWeight || 'normal'};
`;

export const SelectBoxToggleIcon = styled.span<SelectBoxToggleIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || '34px'};
  height: ${props => props.height || '34px'};
  ${props => props.minHeight && `min-height: ${props.minHeight};`}
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: ${props => props.borderLeft || '1px solid #d4d4d4'};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-sizing: border-box;
`;

const SelectBoxToggleContainer = styled.button.attrs<ButtonHTMLAttributes<any>>(() => ({
  type: 'button',
}))<SelectBoxToggleContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  padding: ${props => props.styles?.padding || '0'};
  width: ${props => props.styles?.width || '100%'};
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
  height: ${props => props.styles?.height || 'auto'};
  border: ${props => props.border || '1px solid #d3d3d3'};
  border-radius: ${props => props.borderRadius || '6px'};
  box-shadow: ${props => {
    if (props.hasError) {
      return '0 0 0 2px #ff7b7b';
    }
    return props.open ? props.boxShadowOnOpen || '0 0 0 1px #5db5ff' : props.boxShadow;
  }};
  background-color: ${props => {
    if (props.disabled) {
      return props.backgroundColorOnDisabled || '#F9F9F9';
    }
    if (props.open) {
      return props.backgroundColorOnOpen || props.backgroundColor || '#fff';
    }
    return props.backgroundColor || '#fff';
  }};
  color: ${props => {
    const baseColor = props.color || '#000';
    const disabledColor = props.colorOnDisabled || colors.grey[400];
    return props.disabled ? disabledColor : baseColor;
  }};

  font-size: ${props => props.fontSize || '13px'};
  font-weight: normal;
  line-height: normal;
  ${props => props.styles?.minHeight && `min-height: ${props.styles.minHeight};`}
  ${props => props.styles?.maxHeight && `max-height: ${props.styles.maxHeight};`}

  ${props => {
    if (props.disabled) {
      return 'cursor: not-allowed;';
    }
    return 'cursor: pointer;';
  }}
  user-select: text;

  &:hover {
    box-shadow: ${props => {
      if (props.hasError) {
        return '0 0 0 2px #ff7b7b';
      }
      return props.open ? props.boxShadowOnOpen || '0 0 0 1px #5db5ff' : props.boxShadow;
    }};
  }

  &:focus {
    box-shadow: ${props => {
      if (props.hasError) {
        return '0 0 0 2px #ff7b7b';
      }
      return props.open ? props.boxShadowOnOpen || '0 0 0 1px #5db5ff' : props.boxShadow || '0 0 0 1px #5db5ff';
    }};
  }
`;

export const SelectBoxToggle: React.FC<React.PropsWithChildren<SelectBoxToggleProps>> = ({
  className,
  children,
  labelType = 'plainText',
  labelStyle,
  defaultLabelStyle,
  iconStyle,
  isDisabled,
  hideButton,
  itemRenderer,
  onElementLoaded,
  tooltipEnabled = true,
  ...rest
}) => {
  const {
    values,
    open,
    disabled,
    hasError,
    openDropdown,
    closeDropdown,
    defaultLabel,
    onRemoveClick,
    onToggleElementResized,
  } = useContext(SelectBoxContext);
  const selectBoxToggleElRef = useRef<HTMLButtonElement>(null);
  const prevSelectBoxToggleHeight = useRef<number>(0);

  const handleToggle = () => {
    if (!disabled && !isDisabled) {
      if (open && closeDropdown) {
        closeDropdown();
      } else if (!open && openDropdown) {
        openDropdown();
      }
    }
  };

  const handleRemoveClick = useCallback(
    (value: ValueWithLabel<any>) => {
      if (onRemoveClick) {
        onRemoveClick(value);
      }
    },
    [onRemoveClick]
  );

  const getSelectedLabel = useCallback(() => {
    if (values.length > 0) {
      if (labelType === 'plainText') {
        return values
          .filter(option => option)
          .map(option => option.label)
          .join(', ');
      }

      if (labelType === 'itemWithText') {
        return values.map((option, index) => (itemRenderer ? itemRenderer(option, index) : option.label));
      }

      if (labelType === 'defaultText') {
        return defaultLabel;
      }

      return values.map((option, index) => {
        return (
          <SelectBoxToggleLabelItem
            key={`selectbox-label-item-${uuidv4()}`}
            onRemoveClick={() => handleRemoveClick(option)}
            styles={option.styles}
          >
            {itemRenderer ? itemRenderer(option, index) : option.label}
          </SelectBoxToggleLabelItem>
        );
      });
    }
    return '';
  }, [values, labelType, handleRemoveClick, itemRenderer]);

  const getIconURL = useCallback(() => {
    if (open) {
      return iconStyle?.close?.url ?? IconDownSky;
    }
    return iconStyle?.open?.url ?? IconDown;
  }, [open]);

  useEffect(() => {
    if (values.length > 0 && onToggleElementResized) {
      const nextSelectBoxToggleHeight = selectBoxToggleElRef.current?.clientHeight;
      if (nextSelectBoxToggleHeight && nextSelectBoxToggleHeight !== prevSelectBoxToggleHeight.current) {
        onToggleElementResized(true);
        prevSelectBoxToggleHeight.current = nextSelectBoxToggleHeight;
      } else {
        onToggleElementResized(false);
      }
    }
  }, [values, selectBoxToggleElRef, onToggleElementResized]);

  return (
    <SelectBoxToggleContainer
      ref={el => {
        selectBoxToggleElRef.current = el;
        onElementLoaded?.(el);
      }}
      className={className}
      open={open}
      disabled={disabled}
      hasError={hasError}
      onClick={handleToggle}
      {...rest}
    >
      <TooltipOnHover enableWhenOverflow tooltipContent={getSelectedLabel() || defaultLabel} disabled={!tooltipEnabled}>
        <ToggleLabel labelType={labelType} {...labelStyle}>
          {getSelectedLabel() || <DefaultLabel styles={defaultLabelStyle}>{defaultLabel}</DefaultLabel>}
        </ToggleLabel>
      </TooltipOnHover>
      {!hideButton && (
        <SelectBoxToggleIcon className="toggle-icon" {...iconStyle}>
          {children || <Icon url={getIconURL()} width={10} height={6} />}
        </SelectBoxToggleIcon>
      )}
    </SelectBoxToggleContainer>
  );
};
