import { defaultTheme, legacyColors } from 'theme';
import styled, { css } from 'styled-components';

type ButtonSize = 'large' | 'medium' | 'default';
type ButtonType =
  | 'primary'
  | 'danger'
  | 'default'
  | 'line-primary'
  | 'line-danger'
  | 'line-default'
  | 'text-primary'
  | 'text-danger'
  | 'text-default';

export type ButtonProps = {
  buttonSize?: ButtonSize;
  buttonType?: ButtonType;
  color?: string;
  fontWeight?: 'bold' | 'normal';
};

const colorsByButtonType = {
  backgroundColor: {
    primary: legacyColors.spenditBlue,
    danger: legacyColors.spenditRed,
    default: legacyColors.spenditWhite,
    'line-primary': legacyColors.spenditWhite,
    'line-danger': legacyColors.spenditWhite,
    'line-default': legacyColors.spenditWhite,
    'text-primary': 'transparent',
    'text-danger': 'transparent',
    'text-default': 'transparent',
  },
  borderColor: {
    primary: legacyColors.spenditBlue,
    danger: legacyColors.spenditRed,
    default: 'transparent',
    'line-primary': defaultTheme.mantineColors.blue[6],
    'line-danger': legacyColors.spenditRed,
    'line-default': '#97a9c0',
    'text-primary': 'transparent',
    'text-danger': 'transparent',
    'text-default': 'transparent',
  },
  color: {
    primary: legacyColors.spenditWhite,
    danger: legacyColors.spenditWhite,
    default: legacyColors.spenditBlue,
    'line-primary': defaultTheme.mantineColors.blue[6],
    'line-danger': legacyColors.spenditRed,
    'line-default': '#657c97',
    'text-primary': legacyColors.spenditBlue,
    'text-danger': legacyColors.spenditRed,
    'text-default': legacyColors.spenditGray,
  },
  boxShadow: {
    primary: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    danger: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    default: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    'line-primary': `0 0 4px 0 ${defaultTheme.mantineColors.blue[6]}66`,
    'line-danger': '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    'line-default': '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    'text-primary': '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    'text-danger': '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
    'text-default': '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
  },
  activeBackgroundColor: {
    'line-primary': defaultTheme.mantineColors.blue[2],
  },
};

const disabledColorsByButtonType = {
  backgroundColor: {
    primary: legacyColors.lightGray,
    danger: legacyColors.lightGray,
    default: legacyColors.lightGray,
    'line-primary': legacyColors.spenditWhite,
    'line-danger': legacyColors.lightGray,
    'line-default': legacyColors.lightGray,
    'text-primary': 'transparent',
    'text-danger': 'transparent',
    'text-default': 'transparent',
  },
  borderColor: {
    primary: 'transparent',
    danger: 'transparent',
    default: 'transparent',
    'line-primary': defaultTheme.mantineColors.gray[4],
    'line-danger': 'transparent',
    'line-default': 'transparent',
    'text-primary': 'transparent',
    'text-danger': 'transparent',
    'text-default': 'transparent',
  },
  color: {
    primary: legacyColors.spenditWhite,
    danger: legacyColors.spenditWhite,
    default: legacyColors.spenditWhite,
    'line-primary': defaultTheme.mantineColors.gray[4],
    'line-danger': legacyColors.spenditWhite,
    'line-default': legacyColors.spenditWhite,
    'text-primary': legacyColors.disabledGrayText,
    'text-danger': legacyColors.disabledGrayText,
    'text-default': legacyColors.disabledGrayText,
  },
};

export const BaseButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Button = styled(BaseButton).attrs(({ type }) => ({
  type: type || 'button',
}))<ButtonProps>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: ${props => props.fontWeight || 'bold'};
  vertical-align: top;
  border: 1px solid;
  column-gap: 6px;

  &:disabled {
    cursor: not-allowed;
  }
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    box-shadow: ${({ buttonType }) => colorsByButtonType.boxShadow[buttonType || 'default']};
    transition: 0s;
  }
  ${props => {
    switch (props.buttonSize) {
      case 'large':
        return css`
          padding: 0 28px !important;
          height: 40px;
          font-size: 14px;
        `;
      case 'medium':
        return css`
          padding: 5px 8px;
          font-size: 14px;
          line-height: 21px;
        `;
      case 'default':
      default:
        return css`
          padding: 0 10px;
          height: 30px;
          font-size: 12px;
        `;
    }
  }}

  &:not(:disabled) {
    ${({ color, buttonType }) => css`
      background-color: ${colorsByButtonType.backgroundColor[buttonType || 'default']};
      border-color: ${colorsByButtonType.borderColor[buttonType || 'default']};
      color: ${color || colorsByButtonType.color[buttonType || 'default']};
    `}
  }
  &:disabled {
    ${({ color, buttonType }) => css`
      background-color: ${disabledColorsByButtonType.backgroundColor[buttonType || 'default']};
      border-color: ${disabledColorsByButtonType.borderColor[buttonType || 'default']};
      color: ${color || disabledColorsByButtonType.color[buttonType || 'default']};
    `}
  }

  &:not(:disabled):active {
    background-color: ${({ buttonType }) => colorsByButtonType.activeBackgroundColor[buttonType || 'default']};
  }
`;

export const LinkButton = styled.a.attrs(({ target }) => ({
  target: target ?? 'blank',
  rel: 'noopener noreferrer',
}))<ButtonProps>`
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  font-weight: ${props => props.fontWeight || 'bold'};
  vertical-align: top;
  column-gap: 6px;
  text-decoration: none;

  &:disabled {
    background-color: #d5d5d5;
    border-color: #d5d5d5;
    color: #fff;
  }
  &:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    transition: 0s;
  }
  & + & {
    margin-left: 5px;
  }

  ${props => {
    switch (props.buttonSize) {
      case 'large':
        return css`
          padding: 0 28px !important;
          height: 40px;
          font-size: 14px;
        `;
      case 'medium':
        return css`
          padding: 0 20px !important;
          height: 32px;
          font-size: 14px;
        `;
      case 'default':
      default:
        return css`
          padding: 0 10px !important;
          height: 30px;
          font-size: 12px;
        `;
    }
  }}
  ${props => `
    background-color: ${colorsByButtonType.backgroundColor[props.buttonType || 'default']};
    border-color: ${colorsByButtonType.borderColor[props.buttonType || 'default']};
    color: ${props.color || colorsByButtonType.color[props.buttonType || 'default']};
  `}
`;

export const ButtonGroup = styled.div`
  ${Button}, ${LinkButton} {
    font-weight: normal;

    &:hover {
      box-shadow: none;
    }
  }

  ${Button} + ${Button},
  ${LinkButton} + ${LinkButton} {
    margin-left: 0;
  }
`;
