import * as S from './styles';

import { ButtonHTMLAttributes, PropsWithChildren, forwardRef } from 'react';

import { ButtonStyleProps } from './styles';
import { Spinner } from './Spinner';

type BasicButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>;
export type ButtonProps = PropsWithChildren<BasicButtonAttributes & Partial<ButtonStyleProps>>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      isLoading = false,
      colorScheme = 'blue',
      variant = 'fill',
      size = 'medium',
      disabled,
      leftIcon,
      rightIcon,
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <S.ButtonRoot
        disabled={disabled || isLoading}
        isLoading={isLoading}
        variant={variant}
        size={size}
        colorScheme={colorScheme}
        ref={ref}
        {...props}
      >
        <S.ContentWrapper data-testid={'buttonContentWrapper'} isLoading={isLoading}>
          {leftIcon ? <S.LeftIconWrapper size={size}>{leftIcon}</S.LeftIconWrapper> : null}
          {children}
          {rightIcon ? <S.RightIconWrapper size={size}>{rightIcon}</S.RightIconWrapper> : null}
        </S.ContentWrapper>
        {isLoading && <Spinner role={'spinner'} colorScheme={colorScheme} variant={variant} size={14} />}
      </S.ButtonRoot>
    );
  }
);
export default Button;
