import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import ContractExceedIcon from 'images/icons/icon-contract-exceed.svg';
import { IconBang } from 'components/atoms/Icon/icons/IconBang';
import { IconError } from 'components/atoms/Icon/icons/IconError';
import { IconQuestion } from 'components/atoms/Icon/icons/IconQuestion';
import { IconSuccess } from 'components/atoms/Icon/icons/IconSuccess';
import { ReactElement } from 'react';
import colors from 'theme/colors';
import { merge } from 'lodash-es';
import { translate } from 'utils/LocaleUtil';
import withReactContent from 'sweetalert2-react-content';

export type ShowConfirmPayloadType = Omit<SweetAlertOptions, 'html' | 'text'> & {
  text: string | ReactElement;
};

const MySwal = withReactContent(Swal);

type ShowBoxAlertPayloadType = {
  title?: string;
  text: string;
  boxText: string;
  icon: 'error' | 'success' | 'warning' | 'info' | 'question';
  showCancelButton?: boolean;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
};

/**
 * Show text success message
 */
export const fireSuccess = (text: string): Promise<SweetAlertResult> => {
  return MySwal.fire({
    icon: 'success',
    html: text,
    heightAuto: false,
    allowEnterKey: false,
    confirmButtonColor: '#047DE0',
  });
};

/**
 * Show text error message
 */
export const fireError = (text: string, heightAuto = false): Promise<SweetAlertResult> => {
  return MySwal.fire({
    iconHtml: <IconError />,
    html: text,
    heightAuto,
    confirmButtonText: translate('error_confirm'),
    buttonsStyling: false,
    allowEnterKey: false,
    customClass: {
      icon: 'confirm-icon',
      htmlContainer: 'confirm-html-container',
      confirmButton: 'confirm-button',
    },
  });
};

export const showCustomConfirm = ({
  text = '',
  confirmButtonColor,
  confirmButtonText,
  cancelButtonColor = '#BFC9D4',
  cancelButtonText = translate('cancel'),
}: {
  text: string;
  confirmButtonColor: string;
  confirmButtonText: string;
  cancelButtonColor?: string;
  cancelButtonText?: string;
}): Promise<SweetAlertResult> =>
  MySwal.fire({
    html: text,
    confirmButtonText,
    confirmButtonColor,
    showCancelButton: true,
    cancelButtonColor,
    cancelButtonText,
    allowEnterKey: false,
  });

export const showConfirm = ({
  title = '',
  text = '',
  icon = 'question',
  confirmButtonColor = colors.blue[500],
  cancelButtonColor = '#BFC9D4',
  cancelButtonText = translate('cancel'),
  confirmButtonText = translate('save'),
  ...rest
}: ShowConfirmPayloadType): Promise<SweetAlertResult> =>
  MySwal.fire({
    title,
    html: text,
    iconHtml: (
      <>
        {icon === 'question' && <IconQuestion />}
        {icon === 'error' && <IconError />}
        {icon === 'warning' && <IconBang />}
        {icon === 'success' && <IconSuccess />}
      </>
    ),
    showCancelButton: true,
    allowEnterKey: false,
    confirmButtonColor,
    cancelButtonColor,
    cancelButtonText,
    confirmButtonText,
    ...rest,
    customClass: merge(
      {
        icon: 'confirm-icon',
        htmlContainer: 'confirm-html-container',
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button',
        actions: 'confirm-actions',
      },
      rest.customClass
    ),
  });

export const showAlert = ({
  title = '',
  text = '',
  icon = 'error',
  confirmButtonColor = colors.blue[500],
  confirmButtonText = translate('confirm'),
  ...rest
}: ShowConfirmPayloadType): Promise<SweetAlertResult> =>
  MySwal.fire({
    title,
    html: text,
    iconHtml: (
      <>
        {icon === 'error' && <IconError />}
        {icon === 'warning' && <IconBang />}
        {icon === 'success' && <IconSuccess />}
      </>
    ),
    showCancelButton: false,
    allowEnterKey: false,
    confirmButtonColor,
    confirmButtonText,
    ...rest,
    customClass: Object.assign(rest?.customClass || {}, {
      icon: 'confirm-icon',
      confirmButton: 'confirm-button',
      htmlContainer: 'confirm-html-container',
    }),
  });

export const showWarning = ({
  title = '',
  text = '',
  icon = 'warning',
  iconColor = colors.navy[200],
  confirmButtonColor = colors.blue[500],
  confirmButtonText = translate('confirm'),
  ...rest
}: ShowConfirmPayloadType): Promise<SweetAlertResult> =>
  MySwal.fire({
    title,
    html: text,
    icon,
    iconColor,
    showCancelButton: false,
    allowEnterKey: false,
    confirmButtonColor,
    confirmButtonText,
    ...rest,
  });

export const showBoxAlert = ({
  title,
  text,
  boxText,
  icon,
  showCancelButton = false,
  confirmButtonColor = '#047DE0',
  cancelButtonColor = '#BFC9D4',
  cancelButtonText = translate('cancel'),
  confirmButtonText = translate('confirm'),
}: ShowBoxAlertPayloadType): Promise<SweetAlertResult> => {
  return MySwal.fire({
    title: title && `<div style="font-size:16px;color:#000000;font-weight:bold;text-align:center;">${title}</div>`,
    html:
      `<div style="font-size:16px;color:#555555;font-weight:normal;text-align:center;">${text}</div>` +
      `<div style="border-radius:4px;border:solid 1px #bfc9d4;background-color:#f5f5f5;margin:10px;padding:10px;display:flex;align-items:center;justify-content:center">
    ${boxText}
    </div>`,
    icon,
    showCancelButton,
    confirmButtonColor,
    cancelButtonColor,
    cancelButtonText,
    confirmButtonText,
    allowEnterKey: false,
    heightAuto: false,
  });
};

interface FireContactExceedProps {
  totalUserCount: number;
  costPerAdditionalUser: number;
}

export const fireContractExceed = ({ totalUserCount, costPerAdditionalUser }: FireContactExceedProps) => {
  return MySwal.fire({
    title: '',
    html: translate('exceed_total_contract_member_with_agree', {
      total_user_count: totalUserCount,
      cost_per_additional_user: costPerAdditionalUser,
    }),
    imageUrl: `${ContractExceedIcon}`,
    imageHeight: 80,
    imageWidth: 80,
    showCancelButton: true,
    allowEnterKey: false,
    confirmButtonColor: '#047DE0',
    cancelButtonColor: '#BFC9D4',
    cancelButtonText: translate('cancel'),
    confirmButtonText: translate('agree'),
  });
};
